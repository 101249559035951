import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ProductContext } from "../../components/Store/CartContext";

const TopNav = () => {
  const context = useContext(ProductContext);

  const getCartItems = () => {
    if (!context) {
      return null;
    }

    if (!context.cart) {
      return null;
    }

    const cartData = context.cart();
    if (!cartData) {
      return null;
    }

    if (!cartData.items) {
      return null;
    }

    return cartData.items;
  };

  const cartItemCount = getCartItems();

  return (
    <div>
      <nav style={navStyle}>
        <Link to="/home">
          <img
            style={logoStyle}
            src="https://assets.bash.com/cms/americanswiss_store_logo_9443cc4ac8.png"
            alt="American Swiss"
          />
        </Link>

        <div style={iconsStyle}>
          <a href="https://bash.com/store-finder">
            <i className="icon icon-map" style={iconStyle}></i>
          </a>

          <Link
            to="/cart"
            className="header__control-item header__control-item--alt js-header-trigger-bag"
          >
            <i className="icon icon-shoppingcart" style={iconStyle}></i>
            <div className="header__control-label">
              {cartItemCount && (
                <div
                  className="header__control-item-counter js-component-header-mini-cart-count"
                  data-value="mini-cart"
                >
                  {cartItemCount}
                </div>
              )}
            </div>
          </Link>
        </div>
      </nav>
    </div>
  );
};

const navStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "1180px",
  margin: "0 auto",
  padding: "6px 0px",
};

const logoStyle = {
  fontSize: "24px",
  fontWeight: "bold",
  marginLeft: "10px",
  width: "240px",
  height: "27px"
};

const iconsStyle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginRight: "10px",
  color: "#000",
  padding: "6px 0px",
};

const iconStyle = {
  fontSize: "24px",
  margin: "12px 10px"
};

export default TopNav;